import React, { useState, useEffect } from 'react';
import { IconButton, Snackbar, Tooltip, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { resendPaymentLink } from "../api/paymentOrderAPI";

const EmailButton = ({accessToken, order}) => {
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [emailEnable, setEmailEnable] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState('');

    useEffect(() => {
        if (order.status === "PAID") {
            setTooltipMessage("Order Paid");
            setEmailEnable(false);
        } else if (order.status === "EXPIRED") {
            setTooltipMessage("Link Expired");
            setEmailEnable(false);
        } else if (!order.customer || !order.customer.email) {
            setTooltipMessage("Customer Email not available");
            setEmailEnable(false);
        } else {
            setTooltipMessage("Resend payment link to customer email");
            setEmailEnable(true);
        }
    }, [order]);

    const handleEmailClick = async () => {
        try {
            const response = await resendPaymentLink(accessToken, order.id);
            if (response.status === 200 && response.data.message === "SUCCESS") {
                setSnackbarMessage('Payment Link successfully sent to ' + order.customer.email);
                setSeverity('success');
                setOpen(true);
            } else {
                setSnackbarMessage('Failed to send Payment Link');
                setSeverity('error');
                setOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('Failed to send Payment Link');
            setSeverity('error');
            setOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={tooltipMessage}>
                <div>
                    <IconButton onClick={handleEmailClick}  disabled={!emailEnable}>
                        <EmailIcon fontSize="small"   sx={{ color: emailEnable ? "#0B456E" : "#B0BEC5" }}/>
                    </IconButton>
                </div>
            </Tooltip>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert  variant="filled" onClose={handleClose} severity={severity}  sx={{
                    width: '100%',
                    bgcolor: severity === 'success' ? '#4CAF50' : severity === 'error' ? '#f44336' : undefined,
                }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>

    );
};

export default EmailButton;
